export const IconVideo = ({ width, height, color, className }) => {

  return (
    <svg
      className={className}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M24.1259 16.3419H7.17778C5.42576 16.3419 4 17.7339 4 19.4444V29.7863C4 31.4969 5.42576 32.8889 7.17778 32.8889H24.1259C25.8779 32.8889 27.3037 31.4969 27.3037 29.7863V19.4444C27.3037 17.7339 25.8779 16.3419 24.1259 16.3419ZM35.3128 17.5539C35.0215 17.3606 34.6486 17.3213 34.3255 17.4495L30.3607 18.9977C29.1479 19.4724 28.363 20.6038 28.363 21.8789V28.386C28.363 29.6612 29.1468 30.7926 30.3607 31.2673L34.3255 32.8155C34.4516 32.8651 34.5851 32.8889 34.7185 32.8889C34.9272 32.8889 35.1348 32.8289 35.3128 32.711C35.603 32.5186 35.7778 32.1981 35.7778 31.8547V18.4103C35.7778 18.0669 35.603 17.7463 35.3128 17.5539ZM10.8852 15.3077C13.5132 15.3077 15.6519 13.2197 15.6519 10.6538C15.6519 8.08803 13.5132 6 10.8852 6C8.25716 6 6.11852 8.08803 6.11852 10.6538C6.11852 13.2197 8.25716 15.3077 10.8852 15.3077ZM20.9481 15.3077C23.2849 15.3077 25.1852 13.4524 25.1852 11.1709C25.1852 8.88952 23.2849 7.03419 20.9481 7.03419C18.6114 7.03419 16.7111 8.88952 16.7111 11.1709C16.7111 13.4524 18.6114 15.3077 20.9481 15.3077Z"
        fill={color ||'#4C52B1'}
      />
    </svg>
  )
}