import React
    // , { useState}
    from 'react';

import SelectDefault from 'components/Fields/Select';
import {Layout} from '../../components/Layout';
import {AnimationLayout} from '../../components/AnimationLayout';
import {Input} from 'components/Fields/Input'
import Text from 'components/Text';
import {FooterButtons} from 'components/FooterButtons';
import {Photo} from 'components/photo'

import {CARRIER_OPTIONS_SHIPPO_API, CIRCLES_IMG_URL, FIREBASE_CONFIG} from 'CONSTANTS';

import style from './styles.module.scss';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);

function TrackOptions({
                          nextStep, prevStep, state, setState
                          // , returnToStepZero
                      }) {
    // const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

    // // Listen to the Firebase Auth state and set the local state.
    // useEffect(() => {
    //   const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
    //     setIsSignedIn(!!user);
    //   });
    //   return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    // }, []);
    //
    // if (!isSignedIn) {
    //   returnToStepZero()
    // }
    // TODO - Send user back to login page if not signed in


    const data = CARRIER_OPTIONS_SHIPPO_API

    return (
        <Layout>
            <AnimationLayout>
                <div className={style.background}>
                    <div className={style.circles} style={{backgroundImage: `url(${CIRCLES_IMG_URL})`}}/>
                    <div className={style.content}>
                        <Text.H1 title="Tracking Info For Your Gift"/>
                        <Photo icon/>
                    </div>
                </div>
                <div className={style.actionsContainer}>
                    <div className={style.input}>
                        <Text.P title="Tracking Number" className={style.label}/>
                        <Input type="text" value={state.trackNumber} onChange={(e) => setState({
                            ...state,
                            tracking_number: e.target.value
                        })}/>
                    </div>
                    <div className={style.input}>
                        <Text.P title="Shipping Carrier" className={style.label}/>
                        <SelectDefault options={data} change={(e) => setState(p => ({...p, carrier: e.value}))}/>
                    </div>
                </div>
            </AnimationLayout>
            <FooterButtons nextStep={nextStep} prevStep={prevStep}/>
        </Layout>
    )
}

export default TrackOptions;