import React from 'react';
import PhoneInput from 'react-phone-input-2';

import Text from '../../components/Text';
import { Photo } from '../../components/photo';
import { Layout } from '../../components/Layout';
import { AnimationLayout } from '../../components/AnimationLayout';
import { FooterButtons } from '../../components/FooterButtons';
import { ProgressBar } from '../../components/ProgressBar';

import { CIRCLES_IMG_URL, PHONE_NUMBER_SVG_URL, FIREBASE_CONFIG } from '../../CONSTANTS';

import 'react-phone-input-2/lib/style.css';
import style from './styles.module.scss'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);

function PhoneNumber({ nextStep, prevStep, state, setState, step }) {

  return (
    <Layout>
      <AnimationLayout>
        <div className={style.background}>
          <div className={style.circles} style={{ backgroundImage: `url(${CIRCLES_IMG_URL})` }} />
          <div className={style.content}>
            <Text.H1 title="What's Their Number?"/>
            <Photo image={PHONE_NUMBER_SVG_URL}/>
            <ProgressBar step={step} />
          </div>
        </div>
        <div className={style.actionsContainer}>
          <PhoneInput
            country={'us'}
            buttonClass={style.flagButton}
            inputClass={style.inputContainer}
            containerClass={style.numberContainer}
            value={state.number}
            onChange={phone => setState({
              ...state,
              number: phone
            })}
          />
        </div>
      </AnimationLayout>
      <FooterButtons nextStep={nextStep} prevStep={prevStep}/>
    </Layout>
  );
}

export default PhoneNumber;
