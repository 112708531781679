import clsx from 'clsx';

import style from './styles.module.scss'

export const Layout = ({ children, className }) => {

  return (
    <div id='appContainer' className={clsx(style.container, { [className]: className })}>
      {children}
    </div>
  )
}