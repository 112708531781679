import React from 'react'
import clsx from 'clsx';

import style from './styles.module.scss'

export const Input = ( props, { className }) => {

  return (
    <input {...props} className={clsx(style.input, className)}/>
  )
}