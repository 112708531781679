import React from "react";
// import ReactDOM from "react-dom";
import {createRoot} from 'react-dom/client';
import {
    BrowserRouter, Route,
    // useRoutes,
    // Route,
    Routes,
    // Navigate
} from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/core/styles";

import theme from "assets/theme/theme.js";

// plugins styles from node_modules
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/plugins/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0";

// import AdminLayout from "layouts/Admin.js";
// import RtlLayout from "layouts/RTL.js";
// import AuthLayout from "layouts/Auth.js";
// import SenderLayout from "layouts/Sender.js";
//
// import Index from "views/Index.js";

import CardProfile from "components/inUse/videoReceived/CardProfile";

import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import NonMerchantCreate from "components/inUse/NonMerchantCreate/NonMerchantCreate";

// import {routesJoined} from "./routes";

const firebaseConfig = {
    apiKey: "AIzaSyBYecClCM-W0xg2HTx3kauGwR-DS7sh7qk",
    authDomain: "videocard-1.firebaseapp.com",
    projectId: "videocard-1",
    storageBucket: "videocard-1.appspot.com",
    messagingSenderId: "302993276232",
    appId: "1:302993276232:web:e62d278c9345b0e084877d",
    measurementId: "G-8WQBDRRZKY"
};


const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
getAnalytics(app);

// const routesJoined = () => {
//     let routes = useRoutes([
//         {
//             path: "/message/surprise",
//             element: (props => <CardProfile {...props}/>)
//         },
//         {
//             path: "/create",
//             name: "Non Merchant Create",
//             miniName: "Create",
//             component: NonMerchantCreate,
//             layout: "/message",
//         }
//     ]);
//     return routes
// }

console.log("Looking for routes")
createRoot(document.getElementById("root")).render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline/>
        <BrowserRouter>
            <Routes>
                <Route path="/message/surprise" element={<CardProfile/>}/>
                <Route path="/message/create" element={<NonMerchantCreate/>}/>
                <Route path="/" element={<NonMerchantCreate/>}/>
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);
