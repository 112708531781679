import { motion } from 'framer-motion';

export const AnimationLayout = ({ children }) => {

  const animation = {
    initial: { x: 300, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -300, opacity: 0 }
  }

  return (
    <motion.div {...animation}>
      {children}
    </motion.div>
  )
}