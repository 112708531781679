import clsx from 'clsx';

import Icon from '../Icon';

import style from './styles.module.scss'

export const ProgressBar = ({ step, className }) => {

  return (
    <div className={clsx(style.container, className)}>
      <div className={style.content}>
          <div className={clsx(style.step, { [style.active]: step >= 2 })}>
            <Icon.Label/>
          </div>
          <div className={clsx(style.step, { [style.active]: step >= 3 })}>
            <Icon.Phone/>
          </div>
          <div className={clsx(style.step, { [style.active]: step >= 4 })}>
            <Icon.Person/>
          </div>
          <div className={clsx(style.step, { [style.active]: step >= 5 })}>
            <Icon.Message/>
          </div>
          <div className={clsx(style.step, { [style.active]: step >= 6 })}>
            <Icon.Video/>
          </div>
      </div>
    </div>
  )
}