import React, {useEffect, useState} from 'react';

import Text from '../../components/Text';
import {Layout} from '../../components/Layout';
import {AnimationLayout} from '../../components/AnimationLayout';
import {FooterButtons} from '../../components/FooterButtons';
import {Photo} from '../../components/photo';
import {Input} from '../../components/Fields/Input';
import {ProgressBar} from '../../components/ProgressBar';

import {
    RECIPIENT_NAME_SVG_URL, CIRCLES_IMG_URL,
    FIREBASE_CONFIG
} from '../../CONSTANTS';

import style from './styles.module.scss'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);

function RecipientName({
                           nextStep, prevStep, state, setState, step
                           // , returnToStepZero
                       }) {

    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    let signInComponent
    if (!isSignedIn) {
        // returnToStepZero()

        // prevStep()
        signInComponent = (
            <div style={{zIndex: 999}}>
                {/*    <h1>My App</h1>*/}
                {/*    <p>Please sign-in:</p>*/}
                {/*    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>*/}
            </div>
        );
    } else {
        signInComponent = (
            <div style={{zIndex: 999, paddingTop: "10px"}}>
                <Text.P>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</Text.P>
                {/*<button onClick={() => firebase.auth().signOut()}>Sign-out</button>*/}
            </div>
        );
    }


    return (
        <Layout>
            <AnimationLayout>
                <div className={style.background}>
                    <div className={style.circles} style={{backgroundImage: `url(${CIRCLES_IMG_URL})`}}/>
                    <div className={style.content}>
                        <Text.H1 title="Who are you sending this to?"/>
                        <Photo image={RECIPIENT_NAME_SVG_URL}/>
                        <ProgressBar step={step}/>
                    </div>
                </div>
                <div className={style.actionsContainer} style={{paddingTop: "10px"}}>
                    <Input type="text" value={state.RecipntName} onChange={(e) => setState({
                        ...state,
                        RecipntName: e.target.value
                    })}/>
                    {isSignedIn && signInComponent}
                </div>
            </AnimationLayout>
            <FooterButtons nextStep={nextStep} prevStep={prevStep}/>
        </Layout>
    );
}

export default RecipientName;
