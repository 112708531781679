import { IconClose } from './IconClose'
import { IconPhone } from './IconPhone'
import { IconPerson } from './IconPerson';
import { IconMessage } from './IconMessage';
import { IconVideo } from './IconVideo';
import { IconLabel } from './IconLabel';
import { IconDelivery } from './IconDelivery';

const Icon = {
  Close: IconClose,
  Phone: IconPhone,
  Person: IconPerson,
  Message: IconMessage,
  Video: IconVideo,
  Label: IconLabel,
  Delivery: IconDelivery
}

export default Icon