export const IconPhone = ({ width, height, color, className }) => {

  return (
    <svg
      className={className}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M25.2019 5H14.8732C12.7365 5 11 6.81543 11 9.0493V30.6455C11 32.8794 12.7365 34.6948 14.8732 34.6948H25.2019C27.3386 34.6948 29.0751 32.8794 29.0751 30.6455V9.0493C29.0751 6.81543 27.3386 5 25.2019 5ZM22.6197 31.9953H17.4554V30.6455H22.6197V31.9953ZM26.8157 27.946H13.2594V9.0493H26.8157V27.946Z"
        fill={color ||'#4C52B1'}
      />
    </svg>
  )
}