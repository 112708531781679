import React, { useEffect, useState } from 'react';

import Text from '../../components/Text';
import { Photo } from '../../components/photo';
import { Layout } from '../../components/Layout';
import { AnimationLayout } from '../../components/AnimationLayout';
import { FooterButtons } from '../../components/FooterButtons';

import { MESSAGE_SVG_URL, CIRCLES_IMG_URL, FIREBASE_CONFIG, FIREBASE_UI_CONFIG } from '../../CONSTANTS';

import style from './styles.module.scss';


import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);


function AuthUser({ nextStep, prevStep }) {


  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  let signInComponent
  if (!isSignedIn) {
    signInComponent = (
        <StyledFirebaseAuth uiConfig={FIREBASE_UI_CONFIG} firebaseAuth={firebase.auth()}/>
    );
  } else {
    nextStep()
    signInComponent = (
      <>
        <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
        <button onClick={() => firebase.auth().signOut()}>Sign-out</button>
      </>
    );
  }


  return (
    <Layout>
      <AnimationLayout>
        <div className={style.background}>
          <div className={style.circles} style={{ backgroundImage: `url(${CIRCLES_IMG_URL})` }}/>
          <div className={style.content}>
            <Text.H1 title="Sign In To Send A CamKard"/>
            <Photo className={style.imageContainerMessage} image={MESSAGE_SVG_URL}/>
          </div>
        </div>
        <div className={style.actionsContainer}>
          {signInComponent}
        </div>
      </AnimationLayout>
      <FooterButtons nextStep={nextStep} prevStep={prevStep}/>
    </Layout>
  );
}

export default AuthUser;
