export const IconPerson = ({ width, height, color, className }) => {

  return (
    <svg
      className={className}
      width={width || '40'}
      height={height || '40'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M17.0416 25.045C19.0212 24.882 21.0098 24.882 22.9894 25.045C24.0673 25.1093 25.1402 25.2471 26.2012 25.4577C28.497 25.9329 29.9958 26.7082 30.6382 27.9587C31.1206 28.9346 31.1206 30.0965 30.6382 31.0724C29.9958 32.3229 28.5565 33.1482 26.1774 33.5734C25.1172 33.7919 24.0441 33.934 22.9656 33.9986C21.9664 34.1111 20.9671 34.1111 19.956 34.1111H18.136C17.7554 34.0611 17.3866 34.0361 17.0297 34.0361C15.9511 33.9794 14.8778 33.8415 13.818 33.6234C11.5221 33.1732 10.0233 32.3729 9.38095 31.1224C9.13298 30.638 9.00225 30.0969 9.00014 29.5468C8.99513 28.9934 9.1219 28.4474 9.36906 27.9587C9.99952 26.7082 11.4983 25.8954 13.818 25.4577C14.8825 25.2441 15.9595 25.1062 17.0416 25.045ZM20.0036 6C23.9914 6 27.2242 9.39838 27.2242 13.5905C27.2242 17.7826 23.9914 21.181 20.0036 21.181C16.0158 21.181 12.7831 17.7826 12.7831 13.5905C12.7831 9.39838 16.0158 6 20.0036 6Z"
        fill={color ||'#4C52B1'}
      />
    </svg>
  )
}