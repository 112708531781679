import React, {useState} from 'react'

import PhoneNumber from '../../../containers/PhoneNumber'
import SenderName from '../../../containers/SenderName'
import RecipientName from '../../../containers/RecipientName'
import Message from '../../../containers/Message'
import UploadVideo from '../../../containers/UploadVideo'
import AuthUser from "../../../containers/AuthUser";
import TrackOptions from 'containers/TrackOptions';
import style from './styles.module.scss'

function Sender() {
    const minSteps = 0
    const maxSteps = 6

    const [step, setStep] = useState(0);
    const [state, setState] = useState({
        number: '',
        carrier: '',
        tracking_number: '',
        senderName: '',
        RecipntName: '',
        msg: '',
        senderEmail: '',
    })

    const nextStep = () => setStep(Math.min(step + 1, maxSteps));
    const prevStep = () => setStep(Math.max(step - 1, minSteps))
    const returnToStepZero = () => setStep(0)

    switch (step) {
        case 0:
            return <div className={style.background}><AuthUser className={style.mainContainer} step={step}
                                                               nextStep={nextStep} prevStep={prevStep} state={state}
                                                               setState={setState} returnToStepZero={returnToStepZero}/>
            </div>
        case 1:
            return <div className={style.background}><TrackOptions className={style.mainContainer} step={step}
                                                                   nextStep={nextStep} prevStep={prevStep} state={state}
                                                                   setState={setState}
                                                                   returnToStepZero={returnToStepZero}/></div>
        case 2:
            return <div className={style.background}><RecipientName className={style.mainContainer} step={step}
                                                                    nextStep={nextStep} prevStep={prevStep}
                                                                    state={state} setState={setState}
                                                                    returnToStepZero={returnToStepZero}/></div>
        case 3:
            return <div className={style.background}><PhoneNumber className={style.mainContainer} step={step}
                                                                  nextStep={nextStep} prevStep={prevStep} state={state}
                                                                  setState={setState} returnToStepZero={returnToStepZero}/></div>
        case 4:
            return <div className={style.background}><SenderName className={style.mainContainer} step={step}
                                                                 nextStep={nextStep} prevStep={prevStep} state={state}
                                                                 setState={setState} returnToStepZero={returnToStepZero}/></div>
        case 5:
            return <div className={style.background}><Message className={style.mainContainer} step={step}
                                                              nextStep={nextStep} prevStep={prevStep} state={state}
                                                              setState={setState} returnToStepZero={returnToStepZero}/></div>
        case 6:
            return <div className={style.background}><UploadVideo className={style.mainContainer} step={step}
                                                                  nextStep={nextStep} prevStep={prevStep} state={state}
                                                                  setState={setState} returnToStepZero={returnToStepZero}/></div>
        default:
            return <div className={style.background}><RecipientName className={style.mainContainer}
                                                                    nextStep={nextStep} returnToStepZero={returnToStepZero}/></div>
    }
}

export default Sender;
