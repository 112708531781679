export const IconDelivery = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="750" height="750" viewBox="0 0 750 750" fill="none">
        <path d="M110.668 150.585C124.98 138.297 134.21 153.253 130.09 162.793C126.327 173.565 105.406 185.545 105.284 184.048C69.7315 148.589 102.229 132.257 110.668 150.585Z" fill="#AEE1F4"/>
        <path d="M415.679 234.684C414.998 234.684 414.325 234.517 413.704 234.19L357.265 204.433C356.264 203.904 355.526 203.019 355.192 201.936C354.857 200.852 354.963 199.708 355.492 198.705L372.631 166.261C373.723 164.193 376.295 163.399 378.365 164.489L434.804 194.246C436.874 195.337 437.669 197.907 436.577 199.974L419.438 232.419C418.909 233.422 418.023 234.156 416.938 234.49C416.524 234.619 416.101 234.684 415.679 234.684ZM376.386 167.424C376.093 167.424 375.808 167.583 375.66 167.861L358.52 200.305C358.383 200.567 358.425 200.81 358.463 200.928C358.501 201.05 358.6 201.27 358.863 201.411L415.302 231.168C415.565 231.305 415.804 231.263 415.926 231.225C416.048 231.187 416.272 231.084 416.409 230.826L433.549 198.382C433.758 197.983 433.606 197.485 433.206 197.276L376.767 167.515C376.645 167.454 376.516 167.424 376.386 167.424Z" fill="#AEE1F4"/>
        <path d="M397.353 207.679C393.697 207.679 390.307 205.622 388.629 202.243L372.068 168.845C371.825 168.355 371.832 167.773 372.087 167.287L372.627 166.261C373.719 164.193 376.291 163.399 378.361 164.489L434.796 194.243C436.866 195.333 437.661 197.903 436.569 199.97L435.622 201.765C435.363 202.251 434.888 202.585 434.344 202.662L398.882 207.557C398.372 207.641 397.863 207.679 397.353 207.679ZM375.526 168.112L391.699 200.727C392.94 203.228 395.625 204.615 398.384 204.178L433.008 199.4L433.545 198.382C433.754 197.983 433.602 197.485 433.202 197.276L376.767 167.519C376.367 167.31 375.869 167.462 375.66 167.861L375.526 168.112Z" fill="#AEE1F4"/>
        <path d="M42.473 482.175C59.597 475.881 62.8917 492.548 55.9295 499.796C48.876 508.28 25.8665 512.061 26.2621 510.663C6.07175 466.531 41.0387 462.722 42.473 482.175Z" fill="#FBE9DB"/>
        <path d="M225.268 228.92C225.147 228.699 225.07 228.456 225.048 228.209C217.686 201.668 225.504 191.865 229.217 188.881C233.25 185.639 238.516 185.092 242.967 187.449C247.152 189.668 249.704 194.035 249.925 199.144C260.205 197.836 263.865 203.218 264.987 205.716C267.502 211.299 265.402 218.996 260.304 222.903C255.51 227.08 247.019 228.92 240.73 229.733C237.488 230.151 227.783 231.014 225.812 229.543C225.58 229.368 225.398 229.156 225.268 228.92ZM245.173 194.237C244.295 192.637 243.005 191.337 241.361 190.466C238.143 188.76 234.312 189.17 231.367 191.542C228.202 194.089 221.616 202.595 228.213 226.803C232.314 227.441 251.032 226.495 258.09 220.292C258.12 220.265 258.15 220.239 258.185 220.212C262.058 217.274 263.743 211.276 261.867 207.114C260.018 203.009 255.274 201.489 248.51 202.834C247.981 202.941 247.437 202.789 247.038 202.432C246.638 202.074 246.425 201.55 246.471 201.014C246.68 198.467 246.216 196.137 245.173 194.237Z" fill="#AEE1F4"/>
        <path d="M79.4555 367.09C79.1968 367.09 78.9343 367.04 78.687 366.938C77.9869 366.645 77.5114 365.98 77.4619 365.223L76.2521 346.314L59.9728 336.588C59.3222 336.2 58.9455 335.474 59.0064 334.714C59.0673 333.957 59.5543 333.3 60.2581 333.019L77.8842 326.025L82.111 307.553C82.2822 306.812 82.8567 306.234 83.5948 306.055C84.3328 305.877 85.1089 306.139 85.5959 306.724L97.7017 321.312L116.591 319.621C117.348 319.552 118.079 319.921 118.474 320.567C118.87 321.213 118.862 322.03 118.455 322.673L108.309 338.682L115.758 356.109C116.058 356.808 115.933 357.614 115.438 358.192C114.944 358.77 114.164 359.013 113.429 358.827L95.0538 354.133L80.768 366.596C80.399 366.922 79.9272 367.09 79.4555 367.09ZM65.493 335.238L79.2006 343.425C79.7636 343.764 80.1251 344.357 80.1669 345.01L81.1865 360.936L93.2162 350.442C93.7108 350.009 94.388 349.849 95.0234 350.012L110.496 353.965L104.223 339.29C103.964 338.686 104.021 337.994 104.371 337.439L112.916 323.957L97.0093 325.383C96.355 325.44 95.712 325.174 95.2935 324.668L85.1013 312.384L81.5441 327.937C81.3958 328.575 80.9468 329.104 80.3343 329.347L65.493 335.238Z" fill="#AEE1F4"/>
        <path d="M528.057 171.965C527.855 171.965 527.649 171.927 527.455 171.843C526.904 171.615 526.531 171.091 526.493 170.498L525.542 155.652L512.762 148.016C512.249 147.712 511.956 147.142 512.005 146.545C512.055 145.952 512.435 145.435 512.991 145.215L526.831 139.722L530.149 125.218C530.282 124.637 530.735 124.181 531.313 124.044C531.895 123.903 532.5 124.108 532.884 124.568L542.388 136.02L557.218 134.69C557.811 134.637 558.386 134.926 558.698 135.435C559.01 135.944 559.002 136.587 558.683 137.088L550.716 149.658L556.563 163.341C556.799 163.888 556.7 164.523 556.312 164.975C555.924 165.428 555.312 165.621 554.734 165.473L540.307 161.79L529.091 171.574C528.798 171.832 528.429 171.965 528.057 171.965ZM517.1 146.959L527.862 153.39C528.304 153.653 528.589 154.12 528.623 154.633L529.422 167.134L538.869 158.894C539.257 158.556 539.79 158.426 540.288 158.556L552.436 161.657L547.513 150.137C547.311 149.662 547.353 149.118 547.631 148.685L554.338 138.103L541.848 139.221C541.334 139.266 540.828 139.057 540.501 138.662L532.5 129.019L529.704 141.231C529.59 141.733 529.232 142.147 528.753 142.337L517.1 146.959Z" fill="#FBD5B7"/>
        <path d="M620.126 252.588L609.592 267.455L687.403 328.595L620.126 252.588Z" fill="#FBD5B7"/>
        <path d="M599.918 285.497L603.649 303.614L683.944 325.785L599.918 285.497Z" fill="#FBD5B7"/>
        <path d="M687.402 328.594L553 272.418L601.723 258.924L687.402 328.594Z" fill="#FBE9DB"/>
        <path d="M620.125 252.587L687.402 328.595L650.886 242L620.125 252.587Z" fill="#FBE9DB"/>
        <path d="M603.223 441.81L566.223 386.31C565.801 385.676 565.228 385.157 564.557 384.797C563.886 384.438 563.136 384.25 562.375 384.25H506.875C505.648 384.25 504.472 384.737 503.605 385.605C502.737 386.472 502.25 387.648 502.25 388.875V518.375C502.25 519.602 502.737 520.778 503.605 521.645C504.472 522.513 505.648 523 506.875 523H525.375C526.602 523 527.778 522.513 528.645 521.645C529.513 520.778 530 519.602 530 518.375C530 512.242 532.436 506.36 536.773 502.023C541.11 497.686 546.992 495.25 553.125 495.25C559.258 495.25 565.14 497.686 569.477 502.023C573.814 506.36 576.25 512.242 576.25 518.375C576.25 519.602 576.737 520.778 577.605 521.645C578.472 522.513 579.648 523 580.875 523H599.375C600.602 523 601.778 522.513 602.645 521.645C603.513 520.778 604 519.602 604 518.375V444.375C604 443.462 603.729 442.569 603.223 441.81Z" fill="#F7F0E8"/>
        <path d="M585.604 437.185L560.953 400.185C560.53 399.551 559.958 399.031 559.286 398.672C558.614 398.312 557.864 398.125 557.102 398.125H530C528.773 398.125 527.597 398.612 526.73 399.48C525.862 400.347 525.375 401.523 525.375 402.75V439.75C525.375 440.977 525.862 442.153 526.73 443.02C527.597 443.888 528.773 444.375 530 444.375H581.754C582.591 444.375 583.412 444.148 584.131 443.718C584.849 443.288 585.437 442.671 585.832 441.932C586.227 441.194 586.414 440.363 586.374 439.527C586.333 438.691 586.067 437.881 585.602 437.185H585.604Z" fill="#B3D8F4"/>
        <path d="M506.875 347.25H201.625C200.398 347.25 199.222 347.737 198.355 348.605C197.487 349.472 197 350.648 197 351.875V518.375C197 519.602 197.487 520.778 198.355 521.645C199.222 522.513 200.398 523 201.625 523H224.75C225.977 523 227.153 522.513 228.02 521.645C228.888 520.778 229.375 519.602 229.375 518.375C229.375 512.242 231.811 506.36 236.148 502.023C240.485 497.686 246.367 495.25 252.5 495.25C258.633 495.25 264.515 497.686 268.852 502.023C273.189 506.36 275.625 512.242 275.625 518.375C275.625 519.602 276.112 520.778 276.98 521.645C277.847 522.513 279.023 523 280.25 523H308C309.227 523 310.403 522.513 311.27 521.645C312.138 520.778 312.625 519.602 312.625 518.375C312.625 512.242 315.061 506.36 319.398 502.023C323.735 497.686 329.617 495.25 335.75 495.25C341.883 495.25 347.765 497.686 352.102 502.023C356.439 506.36 358.875 512.242 358.875 518.375C358.875 519.602 359.362 520.778 360.23 521.645C361.097 522.513 362.273 523 363.5 523H506.875C508.102 523 509.278 522.513 510.145 521.645C511.013 520.778 511.5 519.602 511.5 518.375V351.875C511.5 350.648 511.013 349.472 510.145 348.605C509.278 347.737 508.102 347.25 506.875 347.25Z" fill="#E62552"/>
        <path d="M534.625 467.5H530C528.773 467.5 527.597 467.013 526.73 466.145C525.862 465.278 525.375 464.102 525.375 462.875C525.375 461.648 525.862 460.472 526.73 459.605C527.597 458.737 528.773 458.25 530 458.25H534.625C535.852 458.25 537.028 458.737 537.895 459.605C538.763 460.472 539.25 461.648 539.25 462.875C539.25 464.102 538.763 465.278 537.895 466.145C537.028 467.013 535.852 467.5 534.625 467.5Z" fill="#B3D8F4"/>
        <path d="M228.496 499.875H197V518.375C197 519.602 197.487 520.778 198.355 521.645C199.222 522.513 200.398 523 201.625 523H224.75C225.364 522.994 225.971 522.868 226.536 522.627C227.101 522.386 227.613 522.037 228.043 521.598C228.472 521.159 228.811 520.64 229.04 520.07C229.268 519.5 229.382 518.891 229.375 518.276C229.362 514.259 230.434 510.312 232.477 506.853C232.892 506.15 233.114 505.351 233.12 504.535C233.127 503.719 232.917 502.917 232.513 502.208C232.108 501.499 231.524 500.91 230.819 500.501C230.113 500.091 229.312 499.875 228.496 499.875Z" fill="#C4204A"/>
        <path d="M311.746 499.875H276.504C275.688 499.875 274.887 500.091 274.181 500.501C273.476 500.91 272.891 501.499 272.487 502.208C272.083 502.917 271.873 503.72 271.88 504.535C271.886 505.351 272.108 506.151 272.523 506.853C274.583 510.341 275.655 514.324 275.625 518.375C275.625 519.602 276.113 520.778 276.98 521.645C277.847 522.513 279.024 523 280.25 523H308C309.227 523 310.403 522.513 311.271 521.645C312.138 520.778 312.625 519.602 312.625 518.375C312.595 514.324 313.668 510.341 315.728 506.853C316.143 506.151 316.365 505.351 316.371 504.535C316.378 503.72 316.168 502.917 315.764 502.208C315.359 501.499 314.775 500.91 314.069 500.501C313.364 500.091 312.562 499.875 311.746 499.875Z" fill="#C4204A"/>
        <path d="M533.134 502.208C532.731 501.499 532.147 500.91 531.442 500.5C530.737 500.091 529.936 499.875 529.121 499.875H359.754C358.938 499.875 358.136 500.091 357.431 500.501C356.726 500.91 356.141 501.499 355.737 502.208C355.332 502.917 355.123 503.72 355.129 504.535C355.135 505.351 355.357 506.151 355.772 506.853C357.833 510.341 358.905 514.324 358.875 518.375C358.875 519.602 359.362 520.778 360.229 521.645C361.097 522.513 362.273 523 363.5 523H525.375C526.601 523 527.778 522.513 528.645 521.645C529.513 520.778 530 519.602 530 518.375C529.97 514.323 531.043 510.34 533.103 506.851C533.518 506.149 533.74 505.349 533.745 504.534C533.751 503.718 533.54 502.915 533.134 502.208V502.208Z" fill="#C4204A"/>
        <path d="M577.129 499.875C576.313 499.875 575.512 500.091 574.807 500.5C574.101 500.909 573.517 501.498 573.112 502.207C572.708 502.915 572.498 503.718 572.504 504.533C572.509 505.349 572.731 506.148 573.145 506.851C575.189 510.311 576.262 514.258 576.25 518.276C576.243 518.89 576.357 519.5 576.585 520.07C576.814 520.64 577.153 521.159 577.582 521.598C578.012 522.037 578.524 522.386 579.089 522.627C579.654 522.868 580.261 522.994 580.875 523H599.375C600.602 523 601.778 522.513 602.646 521.645C603.513 520.778 604 519.602 604 518.375V499.875H577.129Z" fill="#E5D8CA"/>
        <path d="M474.5 301H372.75C370.196 301 368.125 303.071 368.125 305.625V398.125C368.125 400.679 370.196 402.75 372.75 402.75H474.5C477.054 402.75 479.125 400.679 479.125 398.125V305.625C479.125 303.071 477.054 301 474.5 301Z" fill="#FCCC97"/>
        <path d="M409.75 301H372.75C371.523 301 370.347 301.487 369.48 302.355C368.612 303.222 368.125 304.398 368.125 305.625V338H409.75C410.977 338 412.153 337.513 413.02 336.645C413.888 335.778 414.375 334.602 414.375 333.375V305.625C414.375 304.398 413.888 303.222 413.02 302.355C412.153 301.487 410.977 301 409.75 301Z" fill="#FFB655"/>
        <path d="M474.5 301H437.5C436.273 301 435.097 301.487 434.23 302.355C433.362 303.222 432.875 304.398 432.875 305.625V333.375C432.875 334.602 433.362 335.778 434.23 336.645C435.097 337.513 436.273 338 437.5 338H479.125V305.625C479.125 304.398 478.638 303.222 477.77 302.355C476.903 301.487 475.727 301 474.5 301Z" fill="#FFB655"/>
        <path d="M414.375 379.625H382C380.773 379.625 379.597 379.138 378.73 378.27C377.862 377.403 377.375 376.227 377.375 375C377.375 373.773 377.862 372.597 378.73 371.73C379.597 370.862 380.773 370.375 382 370.375H414.375C415.602 370.375 416.778 370.862 417.645 371.73C418.513 372.597 419 373.773 419 375C419 376.227 418.513 377.403 417.645 378.27C416.778 379.138 415.602 379.625 414.375 379.625Z" fill="#FFB655"/>
        <path d="M395.875 393.5H382C380.773 393.5 379.597 393.013 378.73 392.145C377.862 391.278 377.375 390.102 377.375 388.875C377.375 387.648 377.862 386.472 378.73 385.605C379.597 384.737 380.773 384.25 382 384.25H395.875C397.102 384.25 398.278 384.737 399.145 385.605C400.013 386.472 400.5 387.648 400.5 388.875C400.5 390.102 400.013 391.278 399.145 392.145C398.278 393.013 397.102 393.5 395.875 393.5Z" fill="#FFB655"/>
        <path d="M252.5 550.75C270.38 550.75 284.875 536.255 284.875 518.375C284.875 500.495 270.38 486 252.5 486C234.62 486 220.125 500.495 220.125 518.375C220.125 536.255 234.62 550.75 252.5 550.75Z" fill="#57565C"/>
        <path d="M252.5 532.25C260.163 532.25 266.375 526.038 266.375 518.375C266.375 510.712 260.163 504.5 252.5 504.5C244.837 504.5 238.625 510.712 238.625 518.375C238.625 526.038 244.837 532.25 252.5 532.25Z" fill="#3E3D42"/>
        <path d="M335.75 550.75C353.63 550.75 368.125 536.255 368.125 518.375C368.125 500.495 353.63 486 335.75 486C317.87 486 303.375 500.495 303.375 518.375C303.375 536.255 317.87 550.75 335.75 550.75Z" fill="#57565C"/>
        <path d="M335.75 532.25C343.413 532.25 349.625 526.038 349.625 518.375C349.625 510.712 343.413 504.5 335.75 504.5C328.087 504.5 321.875 510.712 321.875 518.375C321.875 526.038 328.087 532.25 335.75 532.25Z" fill="#3E3D42"/>
        <path d="M533.099 506.859C531.049 510.349 529.978 514.327 530 518.375C530 520.919 532.544 523 530 523H511.5V499.875H534.625C536.29 499.875 532.313 500.754 533.145 502.187C533.547 502.902 533.755 503.71 533.746 504.529C533.738 505.349 533.515 506.152 533.099 506.859V506.859Z" fill="#E5D8CA"/>
        <path d="M557.75 550.75C575.63 550.75 590.125 536.255 590.125 518.375C590.125 500.495 575.63 486 557.75 486C539.87 486 525.375 500.495 525.375 518.375C525.375 536.255 539.87 550.75 557.75 550.75Z" fill="#57565C"/>
        <path d="M557.75 532.25C565.413 532.25 571.625 526.038 571.625 518.375C571.625 510.712 565.413 504.5 557.75 504.5C550.087 504.5 543.875 510.712 543.875 518.375C543.875 526.038 550.087 532.25 557.75 532.25Z" fill="#3E3D42"/>
        <path d="M405.125 301V351.875C405.125 352.712 405.352 353.534 405.782 354.252C406.213 354.97 406.83 355.558 407.568 355.953C408.306 356.348 409.137 356.535 409.973 356.494C410.81 356.454 411.619 356.187 412.315 355.723L423.625 348.183L434.935 355.722C435.631 356.186 436.44 356.453 437.276 356.493C438.112 356.533 438.944 356.346 439.682 355.952C440.42 355.557 441.037 354.969 441.467 354.251C441.897 353.533 442.125 352.712 442.125 351.875V301H405.125Z" fill="#F49227"/>
        <path d="M335.75 301H234C231.446 301 229.375 303.071 229.375 305.625V398.125C229.375 400.679 231.446 402.75 234 402.75H335.75C338.304 402.75 340.375 400.679 340.375 398.125V305.625C340.375 303.071 338.304 301 335.75 301Z" fill="#FCCC97"/>
        <path d="M271 301H234C232.773 301 231.597 301.487 230.73 302.355C229.862 303.222 229.375 304.398 229.375 305.625V338H271C272.227 338 273.403 337.513 274.27 336.645C275.138 335.778 275.625 334.602 275.625 333.375V305.625C275.625 304.398 275.138 303.222 274.27 302.355C273.403 301.487 272.227 301 271 301Z" fill="#FFB655"/>
        <path d="M335.75 301H298.75C297.523 301 296.347 301.487 295.48 302.355C294.612 303.222 294.125 304.398 294.125 305.625V333.375C294.125 334.602 294.612 335.778 295.48 336.645C296.347 337.513 297.523 338 298.75 338H340.375V305.625C340.375 304.398 339.888 303.222 339.02 302.355C338.153 301.487 336.977 301 335.75 301Z" fill="#FFB655"/>
        <path d="M275.625 379.625H243.25C242.023 379.625 240.847 379.138 239.98 378.27C239.112 377.403 238.625 376.227 238.625 375C238.625 373.773 239.112 372.597 239.98 371.73C240.847 370.862 242.023 370.375 243.25 370.375H275.625C276.852 370.375 278.028 370.862 278.895 371.73C279.763 372.597 280.25 373.773 280.25 375C280.25 376.227 279.763 377.403 278.895 378.27C278.028 379.138 276.852 379.625 275.625 379.625Z" fill="#FFB655"/>
        <path d="M257.125 393.5H243.25C242.023 393.5 240.847 393.013 239.98 392.145C239.112 391.278 238.625 390.102 238.625 388.875C238.625 387.648 239.112 386.472 239.98 385.605C240.847 384.737 242.023 384.25 243.25 384.25H257.125C258.352 384.25 259.528 384.737 260.395 385.605C261.263 386.472 261.75 387.648 261.75 388.875C261.75 390.102 261.263 391.278 260.395 392.145C259.528 393.013 258.352 393.5 257.125 393.5Z" fill="#FFB655"/>
        <path d="M266.375 301V351.875C266.375 352.712 266.602 353.534 267.032 354.252C267.463 354.97 268.08 355.558 268.818 355.953C269.556 356.348 270.387 356.535 271.223 356.494C272.059 356.454 272.869 356.187 273.565 355.723L284.875 348.183L296.184 355.722C296.881 356.186 297.69 356.453 298.526 356.493C299.362 356.533 300.194 356.346 300.932 355.952C301.67 355.557 302.287 354.969 302.717 354.251C303.147 353.533 303.375 352.712 303.375 351.875V301H266.375Z" fill="#F49227"/>
    </svg>
  )
}