import React from 'react'
import clsx from 'clsx';
import Icon from 'components/Icon'

import style from './styles.module.scss'

export const Photo = ({ image, className, message, icon }) => {
  return (
    <>
      <div className={clsx(style.imageContainer, className)}>
        {icon && <div className={style.imageIconSvg}><Icon.Delivery /></div>}
        {image && <img className={clsx(style.imageIcon, { [style.message]: message })} src={image} alt="icon"/>}
      </div>
    </>
  )
};
