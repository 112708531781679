export const checkMobile = (userAgent) => {
  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  )
}

export const checkTablet = (userAgent) => {
  return Boolean(userAgent.match(/iPad/i))
}
