import { useState } from 'react'
import clsx from 'clsx'
import Select, { components } from 'react-select'

import styles from './styles.module.scss'

/**
 * @param isHiddenInputValue {boolean}  скрывает значение slect при открытом меню
 */

const MenuList = (props) => (
  <components.MenuList {...props}>
    {props.children}
  </components.MenuList>
)

const SelectDefault = ({
  name,
  change,
  isDisabled,
  options = [],
  isMulti,
  isClearable,
  isSearchable = true,
  fullwidth,
  defaultValue,
  className,
  isHiddenInputValue,
  closeMenuOnSelect = true,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [value, setValue] = useState('')

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <div className={styles.select__wrap} data-test-name={name}>
      <Select
        value={
          typeof value === 'string' || typeof value === 'number'
            ? options.reduce(
              (acc, option) => (option.value === value ? option : acc),
              {}
            )
            : value
        }
        className={clsx(isMulti && 'react-select-container', { [className]: className })}
        classNamePrefix={isMulti ? 'react-multi-select' : 'react-select'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleBlur}
        onChange={(e) => {
          change && change(e)
          setValue(e)
          setIsFocused(false)
        }}
        name={name}
        closeMenuOnScroll={true}
        isMulti={isMulti}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        menuPlacement="auto"
        placeholder={<></>}
        isDisabled={isDisabled}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        components={{ MenuList }}
        captureMenuScroll={false}
        defaultValue={defaultValue}
        instanceId={name}
        closeMenuOnSelect={closeMenuOnSelect}
        styles={{
          control: (style, { hasValue }) => ({
            ...style,
            backgroundColor: '#fff',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderColor:
              isMulti && hasValue
                ? '#E37639!important'
                : '#d9dcdc!important',
            borderRadius: "15px"
          }),
          valueContainer: (provided) => ({
            ...provided,
            width: fullwidth ? '100vw' : undefined,
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 50,
            borderRadius: 0,
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            transition: '.5s transform',
            transform: isFocused ? 'rotate(180deg)' : undefined,
            color: isFocused ? '#4C52B1' : '#848E98',
          }),
          singleValue: (provided, { selectProps }) => {
            return {
              ...provided,
              color: '#66727D',
              opacity:
                isHiddenInputValue && selectProps.menuIsOpen ? 0 : 1,
            }
          },
        }}
      />
    </div>
  )
}

export default SelectDefault
