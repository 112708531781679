import clsx from 'clsx'
import * as ReactDOM from 'react-dom'

import Icon from '../../components/Icon';

import style from './styles.module.scss'

export const Modal = ({
                        title,
                        children,
                        handleClose,
                        className,
                        closeClass,
                      }) => {

  return ReactDOM.createPortal(
    <div className={clsx(style.container, className)}>
      <div className={style.overlay} onClick={handleClose}/>
      <div className={style.modal}>
        <h2> {title} </h2>
        <div className={clsx(style.close, closeClass)} onClick={handleClose}>
          <Icon.Close/>
        </div>
        <div className={style.body}>{children}</div>
      </div>
    </div>,
    document.body.querySelector('#root')
  )
}
