import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ReactPlayer from 'react-player'
import axios from 'axios'

import Animation from './Animation'
import {checkMobile, checkTablet} from './utils'

import {
    API_GET_PUBLIC_ORDER_ROUTE,
    VIDEO_BUCKET_URL,
    MESSAGE_TO_RECIPIENT_FIREBASE,
    SENDER_NAME_FIREBASE,
    RECIPIENT_NAME_FIREBASE,
    VIDEO_VIEWER_DOMAIN,
    ORDER_DATA,
    MERCHANT_DATA,
    HEADER_LOGO_URL,
    CAMKARD_LOGO_WHITE_SVG_URL
} from 'CONSTANTS'
import * as messageAnimation from 'assets/animation/message.json';
import planeImage from 'assets/img/reciver/plane.png';

import 'assets/css/customDDAddedStyles.css';
import style from './styles.module.scss'
import {Modal} from "../../Modal";
import Text from "../../Text";
// import {Button} from '../../button'

// const CIRCLES_IMG_URL = 'https://as1.ftcdn.net/v2/jpg/04/73/30/82/1000_F_473308274_RjTQ6qN76RGM5hnGVAa0vGvgbY1dVjao.jpg'
// const CIRCLES_IMG_URL = 'https://as2.ftcdn.net/v2/jpg/01/73/67/95/1000_F_173679594_XGKJb23Mk5QdtiyLVfx8HCpdO0ZLI6Zg.jpg'
// const CIRCLES_IMG_URL = 'https://t4.ftcdn.net/jpg/02/34/95/73/240_F_234957305_fWlLoNApiaAO02PljoeTSefmcbCksnNN.jpg'
// const CIRCLES_IMG_URL = 'https://t3.ftcdn.net/jpg/05/32/62/06/240_F_532620672_iLSYfPadrlaqisEG83BHZwTe54d5UxvY.jpg'
// const CIRCLES_IMG_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/holiday%20background.jpeg' // top contender - blue christmas setting
const CIRCLES_IMG_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/holiday-background.webp' // top contender - blue christmas setting
// const CIRCLES_IMG_URL = 'https://as1.ftcdn.net/v2/jpg/03/99/57/46/1000_F_399574680_WOq2jIhzOWUPAIkzH99UyQCUd52bTjLq.jpg'
// const CIRCLES_IMG_URL = 'https://as1.ftcdn.net/v2/jpg/01/71/15/22/1000_F_171152218_PXdBhQWVFPQtrS55VyYj8bFisOn2dKht.jpg'
// let videoURL = "https://storage.googleapis.com/public-vc-1/userUploads/IMG_4876.mp4"

// const pictureURL = "https://storage.googleapis.com/public-adzen-2/IMG_4876.jpg"

function CardProfile() {
    // eslint-disable-next-line no-unused-vars


    const [orderData, setOrderData] = useState(null);
    const [merchantData, setMerchantData] = useState(null);
    const [videoIsProcessed, setvideoIsProcessed] = useState(null)
    const [isUseAnimation, setIsUseAnimation] = useState(false);
    // eslint-disable-next-line no-unused-vars
    let videoURL = new URLSearchParams(useLocation().search).get('videoURL')
    videoURL = VIDEO_BUCKET_URL + videoURL;
    // let posterURL = new URLSearchParams(useLocation().search).get('posterURL')
    // posterURL = VIDEO_BUCKET_URL + posterURL;
    let merchant_id = new URLSearchParams(useLocation().search).get('merchantID')
    let order_number = new URLSearchParams(useLocation().search).get('orderNumber')

    useEffect(() => {
        // console.log("merchantID is " + merchant_id);
        // console.log("POSTER URL is " + posterURL);
        // console.log(videoURL);

        // let recipientName = false
        // let senderName = false
        // let messageToRecipient = false
        if (orderData == null) {
            fetchData()
        } else {
            // These are 4 or 5 variables needed to show on screen
            console.log(videoURL)
            // console.log(posterURL)
            console.log("Order Data below")
            console.log(orderData)
            // recipientName = orderData["recipientName"]
            // senderName = orderData['senderName']
            // messageToRecipient = orderData["messageToRecipient"]
        }
        setTimeout(() => {
            setIsUseAnimation(true)
            console.log("animation wait over")
        }, 3000);
    })

    const fetchData = async () => {
        // let mimetype = 'application/json'
        // let headers = {
        //     'Content-Type': mimetype,
        //     'Accept': mimetype
        // }
        const response = await axios.post(API_GET_PUBLIC_ORDER_ROUTE,
            {
                merchantID: merchant_id,
                orderNumber: order_number
            });
        if (response !== null) {
            setOrderData(response.data[ORDER_DATA]);
            setMerchantData(response.data[MERCHANT_DATA])
            if (response.data !== null) {
                if (response.data?.orderData?.videoForRecipient !== null) {
                    console.log(response.data)
                    console.log(response.data.videoForRecipient)
                    setvideoIsProcessed(response.data?.orderData?.videoForRecipient.processingStatus)
                }
                if(response.data?.merchantData){
                    let localMerchantStylePreferences = response.data.merchantData["stylePreferences"]
                    document.documentElement.style.setProperty('--merchant-primary-color', localMerchantStylePreferences["primaryColor"]);
                }
            }

        }

    }

    function getParameterValueIfAvailable(object, parameterName) {
        try {
            return object[parameterName]
        } catch (e) {
            console.error(e)
            return null
        }
    }

    const userAgent = window.navigator.userAgent
    const currentVideoWidth = (userAgent) => {
        if (checkMobile(userAgent) || checkTablet(userAgent)) {
            return 'initial'
        }
        return '100%'
    }

    let messageToDisplay = '';
    let senderName = '';
    let recipientName = '';
    if (orderData !== null) {
        messageToDisplay = orderData[MESSAGE_TO_RECIPIENT_FIREBASE]
        senderName = orderData[SENDER_NAME_FIREBASE]
        recipientName = orderData[RECIPIENT_NAME_FIREBASE]
    }

    let showVideoNotYetProcessed = false;
    if (videoIsProcessed === "done") {
        showVideoNotYetProcessed = false;
    } else if (videoIsProcessed !== "done" && orderData && videoIsProcessed !== null) {
        showVideoNotYetProcessed = true
    }


    let headerLogo = getParameterValueIfAvailable(merchantData, HEADER_LOGO_URL)
    if (!(headerLogo !== null) || headerLogo === "undefined" || headerLogo === undefined) { // if header logo null
        console.log("Setting Default Header Logo")
        headerLogo = CAMKARD_LOGO_WHITE_SVG_URL
    }

    console.log("headerLogoURL",headerLogo)

    return (
        <div className={style.container}>
            <div className={style.background} style={{backgroundImage: `url(${CIRCLES_IMG_URL})`}}>
                <header className={style.headerRow}>
                    {headerLogo && merchantData && <div className={style.headerLogo}>
                        <a href="https://camKard.com" target="_blank" rel="noreferrer">
                            <img src={headerLogo}
                                 alt="CamKard"/>
                        </a>
                    </div>
                    }
                </header>
                {recipientName !== '' && <h1 className={style.h1}>Hi, {recipientName}!</h1>}
                {showVideoNotYetProcessed && <Modal
                    title="Your video is processing... It should be ready in a minute or two!"
                    handleClose={() => setvideoIsProcessed("done")}
                >
                    <div className={style.modalContent}>
                        <Text.P className={style.modalText}>Your <span
                            className={style.textPurplePop}>CamKard </span> is on it’s way
                            and we planted a tree for you
                            too! 🌳 🌎 ✨ <br/> You can check again by refreshing this page</Text.P>
                        {/*<Button */}
                        {/*    onClick={(e) => {*/}
                        {/*e.preventDefault();*/}
                        {/*window.location.reload();*/}
                        {/*}} title={"Refresh Page 😊"}>*/}

                        {/*</Button>*/}

                    </div>
                </Modal>}
                <div className={style.mainContainer}>
                    {isUseAnimation ?
                        <div className={style.videoContainer}>
                            <img src={planeImage} className={style.plane} alt="plane"/>
                            <ReactPlayer width={currentVideoWidth(userAgent)} height="100%" url={videoURL}
                                         controls={true}/>
                        </div> :
                        <Animation Pic={messageAnimation}/>
                    }

                </div>
                <div className={style.text}>
                    <p className={style.p}>{messageToDisplay}</p> <br/>
                </div>
                <div className={style.text}>
                    {senderName !== '' && <p className={style.p}>{'-'.concat(senderName)}</p>}
                </div>
                {/*<div className={style.circles} style={{backgroundImage: `url(${CIRCLES_IMG_URL})`}}/>*/}
                <div className={style.circles} />
                <footer className={style.links}>
                    <a href="https://camKard.com" target="_blank" rel="noreferrer">
                        <img src="https://storage.googleapis.com/public-vc-1/staticAssets/svg/logowhite.svg"
                             alt="CamKard"/>
                    </a>
                    <a href="https://camKard.com" target="_blank" rel="noreferrer">Home</a>
                    <a href="https://www.camkard.com/about/what-we-do" target="_blank" rel="noreferrer">What is
                        CamKard?</a>
                    <a href={VIDEO_VIEWER_DOMAIN + "/message/create"} target="_blank" rel="noreferrer">Send another
                        CamKard</a>
                    <p>CamKard © 2022 All Rights Reserved</p>
                </footer>
            </div>
        </div>
    );
}

export default CardProfile;
