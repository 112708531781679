import React from 'react';

import Text from '../../components/Text';
import {Layout} from '../../components/Layout';
import {AnimationLayout} from '../../components/AnimationLayout';
import {FooterButtons} from '../../components/FooterButtons';
import {Photo} from '../../components/photo';
import {Input} from '../../components/Fields/Input';
import {ProgressBar} from '../../components/ProgressBar';

import {CIRCLES_IMG_URL, SENDER_NAME_SVG_URL, FIREBASE_CONFIG} from '../../CONSTANTS';

import style from './styles.module.scss'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);

function SenderName({nextStep, prevStep, state, setState, step}) {

    return (
        <Layout>
            <AnimationLayout>
                <div className={style.background}>
                    <div className={style.circles} style={{backgroundImage: `url(${CIRCLES_IMG_URL})`}}/>
                    <div className={style.content}>
                        <Text.H1 title="What's your name?"/>
                        <Photo image={SENDER_NAME_SVG_URL}/>
                        <ProgressBar step={step}/>
                    </div>
                </div>

                <div className={style.actionsContainer}>
                    <Input value={state.senderName} onChange={(e) => setState({
                        ...state,
                        senderName: e.target.value
                    })}/>
                </div>
            </AnimationLayout>
            <FooterButtons nextStep={nextStep} prevStep={prevStep}/>
        </Layout>
    );
}

export default SenderName;
