import React, { useEffect, useState } from 'react'
import clsx from 'clsx';
import $ from 'jquery'

import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import Text from '../../components/Text';
import { ProgressBar } from '../../components/ProgressBar';
import { Button } from '../../components/button';
import { Layout } from '../../components/Layout';
import { AnimationLayout } from '../../components/AnimationLayout';
import { Modal } from '../../components/Modal';

import {
  PLANE_SVG_URL,
  API_UPLOAD_ROUTE,
  CIRCLES_IMG_URL,
  TREE_IMG_URL, FIREBASE_CONFIG,
} from '../../CONSTANTS'
import iconUpload from '../../assets/img/upload.svg'

import style from './styles.module.scss'
import { v4 as uuidv4 } from 'uuid';
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

firebase.initializeApp(FIREBASE_CONFIG);

// let API_UPLOAD_ROUTE = "https://videocard-1.nn.r.appspot.com/userUpload";

function UploadVideo({ _nextStep, prevStep, state, _setState, step }) {
  const [FileUpload, setFileUpload] = useState({});
  const [fileUrl, setFileUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [isAgree, setIsAgree] = useState(false)
  const [error, setError] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [previewLink, setPreviewLink] = useState('') // TODO - FIX THIS LINK! now it's just sending back to message/create page

  useEffect(() => {
    document.body.style.height = '100vh';
  });
  const handleFiles = (e) => {
    console.log('files');
    let file = e.target.files[0];
    if (file) {
      setFileUrl(URL.createObjectURL(file));
      // console.log();
      setFileUpload(file)
    }
  };

  // eslint-disable-next-line no-unused-vars
  function _getMerchantURL() {
    let URL = window.location.href;
    URL = URL.split('://')[1];
    URL = URL.split('/')[0];
    console.log('got URL of - ' + URL);
    return URL;
  }

  // eslint-disable-next-line no-unused-vars
  function _getOrderNumberFromShopifyPage() {
    let orderText = $('.os-order-number').text(); // querySelector - native js
    console.log(orderText);
    console.log(typeof orderText);
    let orderNumber = orderText.split('#')[1];
    orderNumber = orderNumber.replaceAll(' ', '');
    orderNumber = Number(orderNumber).toString();
    console.log(orderNumber);
    return orderNumber;
  }

  function onFileChange() {
    // ev.preventDefault();
    let merchantURL
    merchantURL = _getMerchantURL();

    let orderNumber;
    if (!merchantURL.includes('localhost')) {
      orderNumber = uuidv4();
    } else {
      orderNumber = 1001
        if(merchantURL.includes("app.camkard.com") || merchantURL.includes("pre-production-videocard.web.app") || merchantURL.includes('localhost')){
            orderNumber = uuidv4();
        }
      merchantURL = 'pre-production-friendlydeliveries.myshopify.com'
    }

    console.log('onFileChange Called');
    // console.log(file.SenderName);
    console.log(state);

    let sendableState = {
      cellNumber: state.number,
      senderName: state.senderName,
      recipientName: state.RecipntName,
      messageToRecipient: state.msg,
      orderNumber: orderNumber,
      merchantURL: merchantURL,
      senderEmail: state.senderEmail,
      agreedToTerms: isAgree,
      userUID: firebase.auth().currentUser.uid,
      carrier: state.carrier,
      tracking_number: state.tracking_number
    };
    sendableState = JSON.stringify(sendableState);
    // let sendableState = this.state
    console.log(sendableState);
    console.log('sendable state above')

    const data = new FormData();
    data.append('file', FileUpload);
    data.append('filename', FileUpload.name);
    data.append('orderNumber', orderNumber);
    data.append('userInputs', sendableState);

    console.log('Data sent');
    setIsLoading(true)
    fetch(API_UPLOAD_ROUTE, {
      method: 'POST',
      body: data,
    }).then((response) => {
      setIsLoading(false)
      setIsShowModal(true)
      response.json().then((body) => {
        // this.setState({ imageURL: `http://localhost:8000/${body.file}` });
        console.log(body);
        console.log('response from backend')
        setPreviewLink(body['recipient_url'])
      });
    }).catch(err => {
      console.error(err)
      setIsLoading(false)
    })
  }

  const handleChangeAgree = () => {
    setIsAgree(prev => !prev)
  }

  useEffect(() => {
    if (isAgree) {
      setError(false)
    }
  }, [isAgree])

  return (
    <Layout>
      {isShowModal && <Modal
        title="You just did a good thing!"
        handleClose={() => setIsShowModal(false)}
      >
        <div className={style.modalContent}>
          <Text.P className={style.modalText}>Your <span className={style.textPurplePop}>CamKard </span> is on it’s way
            and we planted a tree for you
            too!</Text.P>
          <img className={style.modalImage} src={TREE_IMG_URL} alt="tree"/>
          <Text.P className={style.modalText}>You can preview your message
            <b> <a href={previewLink} target="_blank" rel="noreferrer">
              here
            </a></b>
          </Text.P>

        </div>
      </Modal>}
      <AnimationLayout>
        <div className={style.background}>
          <div className={style.circles} style={{ backgroundImage: `url(${CIRCLES_IMG_URL})` }}/>
          <div className={style.content}>
            <Text.H1 title="Video Upload" />
            {!fileUrl && <ProgressBar className={style.reel} step={step} />}
          </div>
        </div>
        <div className={style.actionsContainer} style={{paddingTop: "10px"}}>
          <div
            className={clsx(style.uploadContainer, { [style.uploaded]: fileUrl })}
            style={{
              backgroundColor: !fileUrl ? 'white' : 'black',
            }}
          >

            {!fileUrl && <img src={PLANE_SVG_URL} className={style.iconPlane} alt="plane"/>}

            <input
              type="file"
              name="files"
              onChange={(e) => handleFiles(e)}
              disabled={!isAgree}
              id="files"
              style={{ display: 'none' }}
              accept="video/mp4,video/x-m4v,video/*"
            />

            <label htmlFor="files" className={style.uploadContent} onClick={() => !isAgree ? setError(true) : ''}>
              {!fileUrl && (
                <>
                  <img src={iconUpload} className={style.iconUpload} alt="upload"/>
                  <Text.P className={style.labelUpload}>
                    Click to Upload <br/>
                    Your Video Card
                  </Text.P>
                </>
              )}
            </label>

            {fileUrl && (
              <>
                <video
                  className={style.videoUploaded}
                  src={fileUrl}
                  controls
                  playsInline
                  autoPlay
                  muted
                  style={{marginTop: "10%", marginBottom:"10%"}}
                />
                <label className={style.button} htmlFor="files">
                  Upload New Video
                </label>
              </>
            )}
          </div>
          {!fileUrl && <div className={clsx(style.agree, { [style.error]: error })}>
            <Checkbox
              className={clsx({ [style.error]: error })}
              style={{ padding: 0 }}
              checked={isAgree}
              onChange={handleChangeAgree}
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Text.P>
              I agree to the
              <a
                href="https://www.camkard.com/other-pages/all-policies-active"
                target="_blank" rel="noreferrer"
              >terms of service</a>
            </Text.P>
          </div>}
        </div>
      </AnimationLayout>

      <div className={style.buttonContainer}>
        <Button
          onClick={() => prevStep()} title="Back"
        />
        <Button
          onClick={() => onFileChange()}
          title={isLoading ? <CircularProgress size={32} color="white"/> : 'Finish'}
          className={clsx({ [style.buttonDisable]: !fileUrl })}
          next
          disabled={!fileUrl}
        />
      </div>
    </Layout>
  );
};

export default UploadVideo;
