import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

let API_DOMAIN = 'https://videocard-1.nn.r.appspot.com'
let VIDEO_BUCKET_URL = "https://storage.googleapis.com/public-vc-1/processed/"
let VIDEO_VIEWER_DOMAIN = "https://app.camkard.com"

const REEL_IMG_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/actPhone.png'
const USER_PHOTO_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/phone.png'
const REEL_SEND_IMG_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/sndNameReel.png'
const REEL_SEND_IMG_RECIPIENT_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/rcpntReel.png'
const SEND_NAME_PHOTO_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/name.png'
const SEND_NAME_RECIPIENT_PHOTO_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/recipnt.png'
const SEND_NAME_MESSAGE_PHOTO_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/message.png'
const REEL_SEND_IMG_MESSAGE_URL = 'https://storage.googleapis.com/public-vc-1/staticAssets/img/theme/msgreel.png'
const UPLOAD_REEL_SEND_IMG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/img/uploadReel.png"
const MOBILE_UPLOAD_IMG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/img/mobileUpload.png"
const PLANE_IMG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/img/reciver/plane.png"
const CIRCLES_IMG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/img/circles_png.png"
const CAMKARD_LOGO_WHITE_SVG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/svg/logowhite.svg"
const TREE_IMG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/svg/tree.svg"
//New Image
const PHONE_NUMBER_SVG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/svg/images/phone_svg.svg"
const SENDER_NAME_SVG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/svg/images/sender_name_svg.svg"
const RECIPIENT_NAME_SVG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/svg/images/racipient_name_svg.svg"
const MESSAGE_SVG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/svg/images/message_svg.svg"
const PLANE_SVG_URL = "https://storage.googleapis.com/public-vc-1/staticAssets/svg/images/plane_svg.svg"
const FIREBASE_CONFIG = {
    apiKey: "AIzaSyBYecClCM-W0xg2HTx3kauGwR-DS7sh7qk",
    authDomain: "videocard-1.firebaseapp.com",
    projectId: "videocard-1",
    storageBucket: "videocard-1.appspot.com",
    messagingSenderId: "302993276232",
    appId: "1:302993276232:web:e62d278c9345b0e084877d",
    measurementId: "G-8WQBDRRZKY"
};
firebase.initializeApp(FIREBASE_CONFIG);

// Configure FirebaseUI.
const FIREBASE_UI_CONFIG = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: '/message/create',
    callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false,
    },
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
};


if (process.env.REACT_APP_IS_PRE_PRODUCTION === 1 || process.env.REACT_APP_IS_PRE_PRODUCTION === "1") {
    API_DOMAIN = "https://pre-production-dot-videocard-1.nn.r.appspot.com"
    VIDEO_BUCKET_URL = "https://storage.googleapis.com/public-vc-1-pre-production/processed/"
    VIDEO_VIEWER_DOMAIN = "https://pre-production-videocard.web.app/"

} else if (process.env.REACT_APP_IS_DEVELOPMENT === 1 || process.env.REACT_APP_IS_DEVELOPMENT === "1") {
     API_DOMAIN  = "http://127.0.0.1:5001"
    // API_DOMAIN = "https://pre-production-dot-videocard-1.nn.r.appspot.com"
} else {
    console.log("")
}

let API_UPLOAD_ROUTE = API_DOMAIN + '/userUpload'
let API_GET_PUBLIC_ORDER_ROUTE = API_DOMAIN + '/getOrderDataPublic'
const MESSAGE_TO_RECIPIENT_FIREBASE = "messageToRecipient"
const SENDER_NAME_FIREBASE = "senderName"
const RECIPIENT_NAME_FIREBASE = "recipientName"

const ORDER_DATA = "orderData"
const MERCHANT_DATA="merchantData"

const HEADER_LOGO_URL = "headerLogoURL"

// const API_UPLOAD_ROUTE = 'http://localhost:5000/userUpload'

const CARRIER_OPTIONS_SHIPPO_API_LIST = [
    ["aramex", "Aramex"],
    ["asendia_us", "Asendia US"],
    ["australia_post", "Australia Post (also used for Startrack)"],
    ["axlehire", "Axlehire"],
    ["borderguru", "BorderGuru"],
    ["boxberry", "Boxberry"],
    ["bring", "Bring (also used for Posten Norge)"],
    ["canada_post", "Canada Post"],
    ["cdl", "CDL"],
    ["collect_plus", "CollectPlus"],
    ["correios_br", "CorreiosBR"],
    ["correos_espana", "Correos Espana"],
    ["couriersplease", "Couriers Please"],
    ["deutsche_post", "Deutsche Post"],
    ["dhl_benelux", "DHL Benelux"],
    ["dhl_ecommerce", "DHL eCommerce"],
    ["dhl_express", "DHL Express"],
    ["dhl_germany_c2c", "DHL Germany C2C"],
    ["dhl_germany", "DHL Germany"],
    ["dpd_germany", "DPD GERMANY"],
    ["dpd", "DPD"],
    ["dpd_uk", "DPD UK"],
    ["estafeta", "Estafeta"],
    ["fastway_australia", "Fastway Australia"],
    ["fedex", "FedEx"],
    ["globegistics", "Globegistics"],
    ["gls_deutschland", "GLS Deutschland"],
    ["gls_france", "GLS France"],
    ["gls_us", "GLS US"],
    ["gophr", "Gophr"],
    ["gso", "GSO"],
    ["hermes_germany_b2c", "Hermes Germany B2C"],
    ["hermes_uk", "Hermes UK"],
    ["hongkong_post", "Hongkong Post"],
    ["lasership", "LaserShip"],
    ["lso", "LSO"],
    ["mondial_relay", "Mondial Relay"],
    ["newgistics", "Newgistics"],
    ["new_zealand_post", "New Zealand Post (also used for Pace and CourierPost)"],
    ["nippon_express", "Nippon Express"],
    ["ontrac", "OnTrac"],
    ["orangeds", "OrangeDS"],
    ["parcelforce", "Parcelforce"],
    ["parcel", "Parcel"],
    ["passport", "Passport"],
    ["pcf", "PCF"],
    ["posti", "Posti"],
    ["purolator", "Purolator"],
    ["royal_mail", "Royal Mail"],
    ["rr_donnelley", "RR Donnelley"],
    ["russian_post", "Russian Post"],
    ["sendle", "Sendle"],
    ["skypostal", "SkyPostal"],
    ["stuart", "Stuart"],
    ["ups", "UPS"],
    ["usps", "USPS"],
    ["yodel", "Yodel"]
]

function process_carrier_options(array_of_carriers){
    let carriers_json = []
    array_of_carriers.forEach((carrier_array) =>{
        let starting_carrier = {}
        starting_carrier["label"] = carrier_array[1]
        starting_carrier["value"] = carrier_array[0]
        carriers_json.push(starting_carrier)
    })
    return carriers_json
}

const CARRIER_OPTIONS_SHIPPO_API = process_carrier_options(CARRIER_OPTIONS_SHIPPO_API_LIST)

export {
    API_UPLOAD_ROUTE, API_GET_PUBLIC_ORDER_ROUTE,
    VIDEO_BUCKET_URL, MESSAGE_TO_RECIPIENT_FIREBASE,
    SENDER_NAME_FIREBASE, RECIPIENT_NAME_FIREBASE,
    API_DOMAIN, VIDEO_VIEWER_DOMAIN,

    REEL_IMG_URL,
    USER_PHOTO_URL,
    REEL_SEND_IMG_URL,
    SEND_NAME_PHOTO_URL,
    SEND_NAME_RECIPIENT_PHOTO_URL,
    REEL_SEND_IMG_RECIPIENT_URL,
    SEND_NAME_MESSAGE_PHOTO_URL,
    REEL_SEND_IMG_MESSAGE_URL,
    UPLOAD_REEL_SEND_IMG_URL,
    MOBILE_UPLOAD_IMG_URL,
    PLANE_IMG_URL,
    CIRCLES_IMG_URL,
    CAMKARD_LOGO_WHITE_SVG_URL,
    TREE_IMG_URL,
    PHONE_NUMBER_SVG_URL,
    SENDER_NAME_SVG_URL,
    RECIPIENT_NAME_SVG_URL,
    MESSAGE_SVG_URL,
    PLANE_SVG_URL,
    FIREBASE_CONFIG,
    FIREBASE_UI_CONFIG,

    CARRIER_OPTIONS_SHIPPO_API,

    ORDER_DATA,
    MERCHANT_DATA,
    HEADER_LOGO_URL
}