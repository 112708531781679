import React from "react";
// import Lottie from "lottie-react";
import Lottie from 'react-lottie-player'

import style from './styles.module.scss'

function Animation({ Pic, Message }) {
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: Pic,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };

  console.log("starting animation")

  return (
    <div className={style.animationContainer}>
      <Lottie
        animationData={Pic}
        loop
        play
      />
      {Message && (
        <h3 className="text-secondary text-center mt-4">{Message}</h3>
      )}
    </div>
  );
}

export default Animation;
