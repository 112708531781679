import React from 'react'
import clsx from 'clsx';

import style from './styles.module.scss'

const Heading_1 = ({ title, children, className }) => {
  return (
    <h1 className={clsx(style.h1, className)}>{title || children}</h1>
  )
}

const Paragraph = ({ title, children, className }) => {
  return <p className={(style.p, className)}>{title || children}</p>
}

const Text = {
  H1: Heading_1,
  P: Paragraph,
}

export default Text
