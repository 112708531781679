import React from 'react';

import Text from '../../components/Text';
import { Photo } from '../../components/photo';
import { Textarea } from '../../components/Fields/Textarea';
import { Layout } from '../../components/Layout';
import { AnimationLayout } from '../../components/AnimationLayout';
import { FooterButtons } from '../../components/FooterButtons';
import { ProgressBar } from '../../components/ProgressBar';

import { MESSAGE_SVG_URL, CIRCLES_IMG_URL, FIREBASE_CONFIG } from '../../CONSTANTS';

import style from './styles.module.scss';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);

function Message({ nextStep, prevStep, state, setState, step }) {

  return (
    <Layout>
      <AnimationLayout>
      <div className={style.background}>
        <div className={style.circles} style={{ backgroundImage: `url(${CIRCLES_IMG_URL})` }} />
        <div className={style.content}>
          <Text.H1 title="Want To Send A Message Too?"/>
          <Photo className={style.imageContainerMessage} image={MESSAGE_SVG_URL}/>
          <ProgressBar className={style.imageReel} step={step} />
        </div>
      </div>

      <div className={style.actionsContainer}>
        <Textarea value={state.msg} onChange={(e) => setState({
          ...state,
          msg: e.target.value
        })}/>
      </div>
      </AnimationLayout>
      <FooterButtons nextStep={nextStep} prevStep={prevStep}/>
    </Layout>
  );
}

export default Message;
