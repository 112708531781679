import React from 'react'
import clsx from 'clsx';

import style from './styles.module.scss'

export const Button = ({ title, onClick, className, next, disabled }) => {
  return (
    <button disabled={disabled} className={clsx(style.btn, className, { [style.btnNext]: next })} onClick={onClick}>
      {title}
    </button>
  )
}
