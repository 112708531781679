import React from 'react';

import { Button } from '../../components/button';

import style from './styles.module.scss'

export const FooterButtons = ({nextStep, prevStep, step}) => {

  const currentButtonName = (step) => {
    if(step === 5) {
      return 'Finish'
    }
    return 'Next page'
  }

  return (
    <div className={style.buttonContainer}>
      <Button onClick={() => prevStep()} title="Back"/>
      <Button onClick={() => nextStep()} title={currentButtonName(step)} next/>
    </div>
  )
}